<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Liste des traces"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-data-table
              :items="traces"
              :headers="headers"
              :options.sync="options"
              :loading="loading"
              :server-items-length="count"
              data-cy="lb-traces"
            >
              <template v-slot:item.date="{ item }">
                <span>{{ new Date(item.date).toLocaleString() }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  export default {
    data: () => ({
      traces: [],
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Identifiant', value: 'nomUsager' },
        { text: 'Logs', value: 'message' },
      ],
      options: {},
      count: 0,
      loading: false,
    }),
    mounted () {
      this.charger()
    },
    methods: {
      charger () {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const s = sortDesc[0] ? ',desc' : ''
        const arg = `size=${itemsPerPage}&page=${page - 1}&sort=${sortBy + s}`
        restApiService.get(`/api/traces/info?${arg}`).then((result) => {
          this.traces = result.data
          this.count = parseInt(result.headers['x-total-count'])
          console.log('charger', this.traces)
          this.loading = false
        }).catch((erreur) => {
          alert(erreur)
          this.loading = false
        })
      },
    },
  }
</script>
<style scoped>
.padding15{
  padding: 15px;
}
</style>
